/**
 * @description Convert the json object to query string.
 * @param {JSON} obj Json object that to be processed.
 * @param {string} separator separating the data using this value
 * @param {string} prefix prefix string of object key.
 * @return {string} query string.
 */
export const objectToQuerystring = (obj, separator = '&', prefix = null, type = 'URL') => {
  return Object.keys(obj).filter((key) => ![null, undefined].includes(obj[key])).reduce((str, key, i) => {
    let delimiter;
    let val;
    delimiter = i === 0 ? '' : separator;
    if (Array.isArray(obj[key])) {
      key = encodeURIComponent(key);
      const arrayVar = obj[key].reduce((innerStr, item) => {
        if (type === 'FORM') {
          val = encodeURIComponent(JSON.stringify(item));
        } else {
          val = encodeURIComponent(item);
        }
        return [innerStr, key, '=', val, '&'].join('');
      }, '');
      return [str, delimiter, arrayVar.trimRightString('&')].join('');
    } else {
      key = encodeURIComponent(key);
      if (type === 'FORM') {
        val = encodeURIComponent(JSON.stringify(obj[key]));
      } else {
        val = encodeURIComponent(obj[key]);
      }
      if (prefix) {
        key = prefix + '.' + key;
      }
      return [str, delimiter, key, '=', decodeURIComponent(val)].join('');
    }
  }, '');
}

/**
 * @description After the validation, values will be converted into FormData if needed.
 * @param {json} apiValues form values.
 * @return {FormData} Modified form values to FormData.
 */
export const convertToFormData = (apiValues) => {
  const formData = new FormData();
  for (const key of Object.keys(apiValues)) {
    let value = apiValues[key];
    if (Array.isArray(value)) {
      value = JSON.stringify(value);
    } else if (value && typeof value === 'object' && Object.keys(value).length) {
      value = JSON.stringify(value);
    }
    formData.append(key, value);
  }
  return formData;
}

export const formValueProcess = (fields = [], form = null) => {
  let formValues = {};
  let isValid = true;
  if (form) {
    let formdata = new FormData(form);
    for (const field of fields) {
      if (field) {
        if (isValid)
          isValid = !(field.error && Object.keys(field.error).length > 0)
        let value = formdata.getAll(field.fieldColumn)
        switch (field.fieldType) {
          case 'textbox':
            if (Array.isArray(value) && value.length > 0)
              if (value.length > 1)
                formValues[field.fieldColumn] = value;
              else
                formValues[field.fieldColumn] = value[0];
            break;
          case 'dropdown':
            if (Array.isArray(value) && value.length > 0)
              if (value.length > 1)
                formValues[field.fieldColumn] = value;
              else
                formValues[field.fieldColumn] = value[0];
            break;
          case 'textarea':
            if (Array.isArray(value) && value.length > 0)
              if (value.length > 1)
                formValues[field.fieldColumn] = value;
              else
                formValues[field.fieldColumn] = value[0];
            break;


          default:
            break;
        }
      }
    }
  }
  return {
    isValid,
    values: formValues
  };
}

export const queryStringToJson = (query = '') => {
  const searchString = query.split("?")
  const vars = searchString.length > 1 ? searchString[1].split("&") : searchString[0].split("&");
  let query_string = {};
  for (var i = 0; i < vars.length; i++) {
    const pair = vars[i].split("=");
    if (pair.length === 2) {
      const key = decodeURIComponent(pair[0]);
      const value = decodeURIComponent(pair[1]);
      // If first entry with this name
      if (typeof query_string[key] === "undefined") {
        query_string[key] = decodeURIComponent(value);
        // If second entry with this name
      } else if (typeof query_string[key] === "string") {
        const arr = [query_string[key], decodeURIComponent(value)];
        query_string[key] = arr;
        // If third or later entry with this name
      } else {
        query_string[key].push(decodeURIComponent(value));
      }
    }
  }
  return query_string;
}

export const fullscreenToggle = (elem) => {
  elem = elem || document.documentElement;

  if (!document.fullscreenElement && !document.mozFullScreenElement &&
    !document.webkitFullscreenElement && !document.msFullscreenElement) {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
    }
  } else {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    }
  }
}

export const checkStatus = (response) => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  const error = new Error('Something went wrong. Please try later!');
  error.response = response;
  throw error;
}

export const parseJSON = async (response) => {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  return await response.json();
}

export const timeFormat = (value, format = null) => {
  const sec = parseInt(value);
  let seconds, minutes, hours;
  switch (format) {
    case 'hh:mm:ss': {
      if ([null, undefined, ''].includes(value))
        return `00:00:00`
      hours = Math.floor(sec / 3600);
      minutes = Math.floor((sec - (hours * 3600)) / 60);
      seconds = sec - (hours * 3600) - (minutes * 60);
      if (hours < 10) { hours = "0" + hours; }
      if (minutes < 10) { minutes = "0" + minutes; }
      if (seconds < 10) { seconds = "0" + seconds; }
      return `${hours}:${minutes}:${seconds}`;
    }
    case 'mm:ss': {
      if ([null, undefined, ''].includes(value))
        return `00:00`
      minutes = Math.floor((sec - (Math.floor(sec / 3600) * 3600)) / 60);
      seconds = sec - (Math.floor(sec / 3600) * 3600) - (minutes * 60);
      if (minutes < 10) { minutes = "0" + minutes; }
      if (seconds < 10) { seconds = "0" + seconds; }
      return `${minutes}:${seconds}`;
    }
    case 'ss': {
      if ([null, undefined, ''].includes(value))
        return `00`
      seconds = sec
      if (seconds < 10) { seconds = "0" + seconds; }
      return `${seconds}`;
    }
    default: {
      if ([null, undefined, ''].includes(value))
        return `00:00`
      minutes = Math.floor((sec - (Math.floor(sec / 3600) * 3600)) / 60);
      seconds = sec - (Math.floor(sec / 3600) * 3600) - (minutes * 60);
      if (minutes < 10) { minutes = "0" + minutes; }
      if (seconds < 10) { seconds = "0" + seconds; }
      return `${minutes}:${seconds}`;
    }
  }
}

export const timeSince = (date) => {
  console.log(date)
  let seconds = Math.floor((new Date() - date) / 1000);
  if ((seconds / 86400) >= 1) {
    if (date.getFullYear() === new Date().getFullYear()) {
      if (date.getDate() === new Date().getDate() - 1 && ((seconds / 86400) < 2))
        return `Yesterday, ${date.toLocaleTimeString()}`
      else
        return `${date.getDate()} ${date.toLocaleString('default', { month: 'short' })},${date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`
    }
    else
      return `${date.getDate()} ${date.toLocaleString('default', { month: 'short' })} ${date.getFullYear()},${date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`
  } else if ((seconds / 3600) >= 1) {
    if (date.getDate() === new Date().getDate())
      return `Today, ${date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`
    else
      return `Yesterday, ${date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`
  } else if ((seconds / 60) >= 1) {
    if (date.getDate() === new Date().getDate())
      return `${Math.floor((seconds / 60))} minutes ago`;
    else
      return `Yesterday, ${Math.floor((seconds / 60))} minutes ago`;
  } else
    return "just now";
}

export const calendarStamp = (date) => {
  let seconds = Math.floor((new Date() - date) / 1000);
  if ((date.getFullYear() === new Date().getFullYear())) {
    if (date.getDate() === new Date().getDate() - 1 && ((seconds / 86400) < 2))
      return `Yesterday`
    else if (date.getDate() === new Date().getDate() && ((seconds / 86400) < 1)) {
      return 'Today'
    }
    else {
      return `${date.toLocaleString('default', { weekday: 'short' })},${date.getDate()} ${date.toLocaleString('default', { month: 'short' })}`
    }
  }
  else {
    return `${date.getDate()} ${date.toLocaleString('default', { month: 'short' })} ${date.getFullYear()}`
  }
}

export const getDevice = (MODE) => {
  const MOBILE_MAX_WIDTH = 425;
  const MOBILE_MIN_HEIGHT = 600;
  const TABLET_MAX_WIDTH = 1024;
  const TABLET_MIN_HEIGHT = 600;
  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;

  const isMobile = windowWidth <= MOBILE_MAX_WIDTH;
  const isTablet = windowWidth <= TABLET_MAX_WIDTH && windowWidth > MOBILE_MAX_WIDTH;
  const isDesktop = !isMobile && !isTablet;

  let resolutionType = '';

  let xs = false;
  let sm = false;
  let md = false;
  let lg = false;
  let xl = false;
  if (windowWidth >= 0 && windowWidth <= 599.9) {
    xs = true;
    resolutionType = 'XS';
  }
  if (windowWidth >= 600 && windowWidth <= 959.9) {
    sm = true;
    resolutionType = 'SM';
  }
  if (windowWidth >= 960 && windowWidth <= 1279.9) {
    md = true;
    resolutionType = 'MD';
  }
  if (windowWidth >= 1280 && windowWidth <= 1919.9) {
    lg = true;
    resolutionType = 'LG';
  }
  if (windowWidth >= 1920 && windowWidth <= 4999.9) {
    xl = true;
    resolutionType = 'XL';
  }
  let orientation = '';
  if (window.matchMedia("(orientation: portrait)").matches) {
    orientation = 'portrait';
  } else {
    orientation = 'landscape';
  }
  let deviceType = getDeviceType();
  if (MODE === 'dev') {
    if (isMobile) {
      deviceType = 'mobile';
    } else if (isTablet) {
      deviceType = 'tablet';
    } else if (isDesktop) {
      deviceType = 'desktop';
    }
  }
  const data = {
    xs,
    sm,
    md,
    lg,
    xl,
    type: deviceType,
    resolutionType,
    orientation
  };

  return data;
}

export const getDeviceType = () => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return 'tablet';
  }
  if (
    /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    return 'mobile';
  }
  return 'desktop';
}
