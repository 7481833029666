// import logo from './assets/svg/logo.svg';
import './App.scss';
import Routes from './Routes';
import { GlobalContextProvider } from './shared/contexts/global';
import NotificationIOContext, { NotificationIOContextProvider } from './shared/contexts/notification-socket';
import { useContext } from 'react';

function App({ config }) {
  // const { notificationIO, isConnected } = useContext(NotificationIOContext)
  // console.log(isConnected, notificationIO)

  return (
    <GlobalContextProvider config={config}>
      < NotificationIOContextProvider >
        < Routes ></Routes>
      </NotificationIOContextProvider>
    </GlobalContextProvider >
  );
}

export default App;
