import React, { useCallback, useContext, useEffect, useState } from "react";
import GlobalContext from "./global";
import { Socket } from "../utils/socket";
import { v4 as uuid } from 'uuid';
import { queryStringToJson } from "../utils/common";
// import SessionContext from "./session";

const NotificationIOContext = React.createContext({
  isConnected: false,
  notificationIO: {},
  reconnectNotificationIO: () => { }
});

export const NotificationIOContextProvider = (props) => {
  const { socketPort } = queryStringToJson(window.location.search);
  const { config } = useContext(GlobalContext)
  // const { token, currentUser } = useContext(AuthContext)
  const [notificationIO, setNotificationIO] = useState(null)

  const [isConnected, setIsConnected] = useState(false)
  const initNotification = () => {
    if ((notificationIO === null || !isConnected)) {
      // const notificationSocket = new Socket(`${config.SOCKET_URL}${socketPort ? ':' + socketPort : ''}/notification`, {
      const notificationSocket = new Socket(`${config.SOCKET_URL}${socketPort ? ':' + socketPort : ''}/notification`, {
        query: {
          appkey: "gdSDFGsDSSFwekjasfvASDFGAsdknasfdASDFniasvbkjasdvsdASDF",
          roomID: '619as',
          token: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySUQiOjExMDI0NzgsInNlc3Npb25JRCI6ImU0MjUzYmEyLTBiN2ItNGVjYy05Zjk0LTU3MmQ2YmFjODA5NSIsImxvZ2luSUQiOiJBYllVQ1E1Z1hoWFJOSFYwSVl3TWpzV0s3dU5ITS9leHB5UllxZnVnYzg4PSIsImRvbWFpbklEIjoicHJvZGFwcCIsImlhdCI6MTY1MDU5OTgwOCwiZXhwIjoxNjUzMTkxODA4fQ.1U6_HXdUaYIfcPR7j_qeNXoI_x9lGnMYetyFyd6hgao'
          // roomID: '61c4822a8d6d5fd351cbc952',
          // clientRef: uuid(),
          // msgAppToken: "gdSDFGsDSSFwekjasfvASDFGAsdknasfdASDFniasvbkjasdvsdASDF"
          // domainID: 'hustle_dev'
        },
        headers: {
          appkey: config.APP_KEY
        }
      })
      setNotificationIO(notificationSocket)
    }
    // else if (token === null && notificationIO)
    //   notificationIO.disconnect('all');
  }

  const connectHandler = useCallback((socket) => {
    if (notificationIO) {
      setIsConnected(notificationIO.connected)
    }
  }, [notificationIO])

  const errorHandler = useCallback((err) => {
    if (notificationIO) {
      console.error(err instanceof Error); // true
      console.error(err.message); // not authorized
      console.error(err.data); // { content: "Please retry later" }
    }
  }, [notificationIO])

  useEffect(() => {
    if (notificationIO) {
      notificationIO.on('connect', connectHandler)
      notificationIO.on('disconnect', connectHandler)
      notificationIO.on("connect_error", errorHandler)
    }
    return () => {
      if (notificationIO) {
        notificationIO.off('connect', connectHandler)
        notificationIO.off('disconnect', connectHandler)
        notificationIO.off("connect_error", errorHandler)
        notificationIO.close()
      }
    }
  }, [notificationIO])

  useEffect(() => {
    initNotification();
  }, [])

  const initialContextValue = {
    isConnected,
    notificationIO,
    reconnectNotificationIO: initNotification
  }

  return (
    <>
      <NotificationIOContext.Provider value={initialContextValue}>
        {props.children}
      </NotificationIOContext.Provider>
    </>
  )
}
export default NotificationIOContext;
