import React, { useEffect, useState } from "react";
import { getDevice } from "../utils/common";

export const themes = {
  light: {
    foreground: '#000000',
    background: '#eeeeee',
  },
  dark: {
    foreground: '#ffffff',
    background: '#222222',
  },
};

const GlobalContext = React.createContext({
  config: {},
  theme: themes.dark,
  device: '',
  changeTheme: () => { }
});

export const GlobalContextProvider = (props) => {
  const [theme, setTheme] = useState(sessionStorage.getItem('theme'));
  const [deviceType, setDeviceType] = useState(getDevice(props.config.MODE));

  const changeTheme = (value) => {
    setTheme(theme[value])
  }

  const resize = () => {
    setDeviceType(getDevice(props.config.MODE))
  }

  useEffect(() => {
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize)
    }
  }, [])

  const initialContextValue = {
    config: props.config,
    theme,
    device: deviceType,
    changeTheme
  }
  return (
    <GlobalContext.Provider value={initialContextValue}>
      {props.children}
    </GlobalContext.Provider>
  )
};

export default GlobalContext;
