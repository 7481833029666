import { checkStatus, convertToFormData, objectToQuerystring, parseJSON } from "./common";

export default function request(origin, uri, options = {}) {
  let queryParams = ''
  if (options.params)
    queryParams = `?${objectToQuerystring(options.params)}`

  if (options.body && (options.method === 'POST' || options.method === 'PUT'))
    if (options.headers && options.headers['Content-Type'] && options.headers['Content-Type'] === 'application/x-www-form-urlencoded')
      options.body = convertToFormData(options.body)
    else
      options.body = JSON.stringify(options.body)
  return fetch(`${origin}${uri}${queryParams}`, options)
    .then(checkStatus)
    .then(parseJSON);
}
