import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import request from './shared/utils/request';
const { PUBLIC_URL } = process.env;

try {
  request(`${PUBLIC_URL}`, `/configuration.json`)
    .then((data) => {
      console.log(data)
      ReactDOM.render(
        <React.StrictMode>
          <App config={data} />
        </React.StrictMode>,
        document.getElementById('root')
      );
    })
} catch (error) {
  console.log(error)
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
