import React, { Suspense, lazy, useContext, useEffect } from 'react';

import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';

const chatLoadTest = lazy(() => import('./modules/chat-loadtest/index'))
const chatReceiver = lazy(() => import('./modules/chat-loadtest/ChatReceiver'))
const socketConnect = lazy(() => import('./modules/chat-socket-connect/SocketConnect'))
// const coachMark = lazy(() => import('./modules/coach-marks/CoachMark'))

const Routes = () => {

  return (
    <Router>
      <Suspense fallback={<div style={{ height: '100%' }} className={`fx-grow fx-shrink fx-column fx-center-center`}>
        {/* <Loader loaderMessage={'Please Wait ...'} /> */}
      </div>}>
        <Switch>


          <Route path="/chat-loadtest" component={chatLoadTest} />
          <Route path="/chat-receiver" component={chatReceiver} />
          <Route path="/socket-connect" component={socketConnect} />


          <Route path="*">
            <Redirect to="/socket-connect" />
          </Route>
        </Switch>
      </Suspense>
    </Router>
  )
};

export default Routes
