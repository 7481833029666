import io from "socket.io-client";
import { objectToQuerystring } from "./common";

export class Socket {
  constructor(url, options = {}) {
    const opts = {
      path: "/io/",
      reconnectionDelayMax: 10000,
      reconnectionAttempts: 3,
      reconnection: true,
      transports: ["websocket", "polling"],
      extraHeaders: {},
      withCredentials: false
    }

    if (options.hasOwnProperty('reconnectionDelayMax'))
      opts.reconnectionDelayMax = options.reconnectionDelayMax
    if (options.hasOwnProperty('reconnectionAttempts'))
      opts.reconnectionAttempts = options.reconnectionAttempts
    if (options.hasOwnProperty('reconnection'))
      opts.reconnection = options.reconnection
    if (options.hasOwnProperty('forceNew'))
      opts.forceNew = options.forceNew
    if (options.hasOwnProperty('query'))
      opts.query = objectToQuerystring(options.query)
    if (options.hasOwnProperty('extraHeaders'))
      opts.extraHeaders = options.extraHeaders
    if (options.hasOwnProperty('withCredentials'))
      opts.withCredentials = options.withCredentials

    this.socket = io.connect(url, opts);
    this.socket.on("connect_error", () => {
      this.socket.io.opts.transports = ["polling", "websocket"];
    });
    this.socket.on("connect", () => {
      this.on = this.socket.on
      this.off = this.socket.off
      this.emit = this.socket.emit
    })
    return this.socket;
  }
}

